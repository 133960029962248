import React, {useState, useEffect} from 'react';
import PocketBase from 'pocketbase';
import DefaultImage from '../../assets/images/defaultImage.png';
import './Testimonial.css';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);

const Testimonial = () => {

    const baseUrl = process.env.REACT_APP_API_BASE_URL;


    const [testimonialsList, setTestimonialsList]  = useState();


    useEffect(() => {
        const fetchData = async () => {
          try {
            const records = await pb.collection('testimonials').getFullList();
            setTestimonialsList(records);
          } catch (error) {
            // console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []);

    return (
        <div className="testimonial">
            {testimonialsList && testimonialsList.length > 0 && 
                testimonialsList.map((testimonial, index) => (
                <div
                    className={`card ${index % 2 === 0 ? 'card-left' : 'card-right'}`}
                    key={index}
                >
                    <div className="card-content">
                        <div className={`image-container ${index % 2 === 0 ? 'order-1' : 'order-2'}`}>
                        <img
                            src={
                                testimonial.photo
                                ? `${baseUrl}/files/${testimonial.collectionId}/${testimonial.id}/${testimonial.photo}`
                                : DefaultImage
                            }
                            alt={testimonial.photo || 'Default image description'}
                            className="circular-image"
                            />                        </div>
                        <div className={`text-container ${index % 2 === 0 ? 'order-2' : 'order-1'} ${index % 2 === 0 ? '' : 'text-right'}`}>
                            <p className="description">{testimonial.review}</p>
                            <p className="name">{testimonial.name}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Testimonial;
