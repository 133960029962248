import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <div className="fashion-loading-container">
      <div className="dots">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
      <div className="fashion-icon">
        <div className="model-silhouette">
          <div className="model-head"></div>
          <div className="model-body"></div>
        </div>
      </div>
      <div className="loading-text"></div>
    </div>
  );
};

export default Loading;
