import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import lunr from 'lunr';
import PocketBase from 'pocketbase';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import DownArrow from '../../assets/images/downArrowIcon.svg';
import './AllProducts.css';
import { stripHtml } from "string-strip-html";

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);

const AllProducts = () => {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]); // Store sorted products
  const [loading, setLoading] = useState(true);

  const { searchItem } = useParams();

  const toggleSortModal = () => setSortModalOpen(!sortModalOpen);

  // Handle sorting with search support
  const handleSort = (sortOrder) => {
    const productsToSort = searchItem ? searchResults : allProducts;

    const sorted = [...productsToSort].sort((a, b) => {
      if (sortOrder === 'price') return a.price - b.price;
      if (sortOrder === '-price') return b.price - a.price;
      return 0;
    });

    setSortedProducts(sorted); // Update sorted products
  };

  // Fetch all products on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await pb.collection('products').getFullList({ expand: 'category' });
        setAllProducts(records);
        setLoading(false);

        if (searchItem) {
          performSearch(records); // Search only if query exists
        } else {
          setSortedProducts(records); // Default to all products
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [searchItem]);

  // Perform Lunr.js search
  const performSearch = (products) => {
    const idx = lunr(function () {
      this.ref('id');
      this.field('name');
      this.field('description');
      this.field('category');

      products.forEach((product) => {
        this.add({
          id: product.id,
          name: product.name,
          description: stripHtml(product.description).result,
          category: product.expand.category.title,
        });
      });
    });

    const results = idx.search(searchItem ?? '');

    const matchedProducts = results.map((result) =>
      products.find((product) => product.id === result.ref)
    );

    setSearchResults(matchedProducts);
    setSortedProducts(matchedProducts); 
  };

  if (loading) {
    return <p>Loading products...</p>;
  }

  return (
    <>
      <Navbar />
      <div className="product-gallery">
        <div className="product-header">
          <h1 className="title">All Products</h1>
          <div className="sort-bar">
            <div className="sort-title" onClick={toggleSortModal}>
              <span>Sort by</span>
              <img
                src={DownArrow}
                alt="arrow"
                className={`arrow-icon ${sortModalOpen ? 'up' : 'down'}`}
              />
            </div>
            {sortModalOpen && (
              <div className="sort-modal">
                <p
                  onClick={() => {
                    toggleSortModal();
                    handleSort('-price');
                  }}
                >
                  Price High to Low
                </p>
                <p
                  onClick={() => {
                    toggleSortModal();
                    handleSort('price');
                  }}
                >
                  Price Low to High
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="gallery">
          {sortedProducts.length > 0 ? (
            sortedProducts.map((product) => (
              <div key={product.id} onClick={() => navigate(`/product/${product.id}`)} style={{cursor:'pointer'}}>
                <img
                  src={`${baseUrl}/files/${product.collectionId}/${product.id}/${product.coverPicture}`}
                  alt={product.name}
                />
                <p style={{fontWeight:'600', textTransform:'uppercase'}}>{product.name}</p>
              </div>
            ))
          ) : (
            <p style={{ marginLeft: '40px' }}>No products found.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AllProducts;
