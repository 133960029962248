import React, { useState, useEffect } from 'react';
import './videodisplay.css';
import { PLACEHOLDER } from './image';

const VideoDisplay = () => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoSrc, setVideoSrc] = useState('/horizontal.mp4'); 
  const videoRef = React.useRef(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)'); 
    const updateVideoSrc = () => {
      if (mediaQuery.matches) {
        setVideoSrc('/horizontal.mp4'); 
      } else {
        setVideoSrc('/video.mp4'); 
      }
    };

    updateVideoSrc();
    mediaQuery.addEventListener('change', updateVideoSrc);

    return () => mediaQuery.removeEventListener('change', updateVideoSrc);
  }, []);

  return (
    <>
      <div
        style={{
          backgroundImage: `url('${PLACEHOLDER}')`,
          backgroundColor: '#ba842d',
          filter: 'blur(5px)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh',
          width: '100vw',
          position: 'absolute',
          zIndex: '-4',
        }}
      />

      <video
        key={videoSrc}
        ref={videoRef}
        className={`videoBackground ${videoLoaded ? 'show' : 'hide'}`}
        autoPlay
        loop
        controls={false}
        muted={true}
        playsInline
        onCanPlayThrough={() => setVideoLoaded(true)}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export default VideoDisplay;
