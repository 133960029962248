import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import Repair from '../../components/Repair/Repair';
import '../HelpPage/HelpPage.css';
import DownArrow from '../../assets/images/downArrowIcon.svg'; 
import ServiceForm from '../../components/Service/ServiceForm';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);

const ServicePage = () => {

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const location = useLocation();
  const navigate = useNavigate();

  const [showRepair, setShowRepair] = useState(true);
  const [showPersonalization, setShowPersonalization] = useState(false);
  const [showServicing, setShowServicing] = useState(false);
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [staticContents, setStaticContents] = useState();


  const [successMsg, setSuccessMsg] = useState(false);
  const [error, setError] = useState('');

  const [formData, setFormData] = useState({
    customer_name: '',
    contact_number: '',
    servicing_date: '',
  });

  const handleChange = (option) => {
    setFormData({
      ...formData,
      selectedOption: option.value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'photos') {
      setFormData({
        ...formData,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });

      // Clear error for contact_number when it becomes valid
      if (name === 'contact_number') {
        const contactNumberRegex = /^\d{9}$/;
        if (contactNumberRegex.test(value)) {
          setError('');
        }
      }
    }
  };

  const toggleSortModal = () => {
    setSortModalOpen(!sortModalOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const records = await pb.collection('staticContents').getFullList();
        setStaticContents(records);
      } catch (error) {
        // console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const section = params.get('section');
    if (section === 'repair') {
      setShowRepair(true);
      setShowPersonalization(false);
      setShowServicing(false)
    } else if (section === 'personalization') {
      setShowPersonalization(true);
      setShowRepair(false);
      setShowServicing(false)
    } else if (section === 'servicing') {
      setShowServicing(true);
      setShowPersonalization(false)
      setShowRepair(false)
    }
  }, [location]);


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Check if contact number is exactly 9 digits
    const contactNumberRegex = /^\d{8,12}$/;
    if (!contactNumberRegex.test(formData.contact_number)) {
      setError('Contact number must be between 8 to 12 digits.');
      return;
    }

    // Validation: Ensure all fields are filled
    if (!formData.customer_name || !formData.contact_number || !formData.product_name) {
      setError('Please fill in all fields before submitting.');
      return;
    }

    try {
      // Submit form data to the server
      await pb.collection('repair_form').create(formData);
      setSuccessMsg(true);
      setError('');

      // Reset form fields after successful submission
      setFormData({
        customer_name: '',
        
      });
      
      // Hide success message after 3 seconds
      setTimeout(() => {
        setSuccessMsg(false);
      }, 3000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit the form. Please try again.');
    }
  };

  const options = [
    { label: 'Shoes', value: 'shoes' },
    { label: 'Boot', value: 'boot' },
    { label: 'Jacket', value: 'jacket' },
    { label: 'Shirt', value: 'shirt' },
    { label: 'Pant', value: 'pant' },
  ];

  return (
    <>
      <Navbar />
      <div className="help-container">
        <div className="help-header">
          <h1 className="help-title">SERVICES</h1>
          <div className="help-menu">
            <div 
              style={{borderBottom: `${showRepair ? '1px solid black' : 'white'}`}} 
              onClick={() => { navigate('/service?section=repair'); }}
            >
              <p>Repair</p>
            </div>
            <div 
              style={{borderBottom: `${showPersonalization ? '1px solid black' : 'white'}`}} 
              onClick={() => { navigate('/service?section=personalization');}}
            >
              <p>Personalization</p>
            </div>
            <div 
              style={{borderBottom: `${showServicing ? '1px solid black' : 'white'}`}} 
              onClick={() => { navigate('/service?section=servicing');}}
            >
              <p>Servicing</p>
            </div>
          </div>
        </div>

        <div className="mobile-help-header">
          <h1 className="mobile-help-title">SERVICE</h1>
          <div className="mobile-help-menu">
            <div className="mobile-help-menu-title" onClick={toggleSortModal}>
              <span>Menu</span>
              <img
                src={DownArrow}
                alt="arrow"
                className={`arrow-icon ${sortModalOpen ? 'up' : 'down'}`}
              />
            </div>
            {sortModalOpen && (
              <div className="help-menu-modal">
                <p  onClick={() => { navigate('/service?section=repair'); setSortModalOpen(!sortModalOpen) }}>Repair</p>
                <p onClick={() => { navigate('/service?section=personalization'); setSortModalOpen(!sortModalOpen)  }}>Personalization</p>
                <p  onClick={() => { navigate('/service?section=servicing'); setSortModalOpen(!sortModalOpen)   }}
                >Servicing</p>

              </div>
            )}
          </div>
        </div>

        <div className="help-content">
          {showRepair && staticContents && staticContents.length > 0 && (
            <div>

              <Repair repair={staticContents[0].repair} />
            </div>
          )}
          {showPersonalization && staticContents && staticContents.length > 0 && (
            <div className='product-care'>
              <h4 className='product-care-title'>Personalization</h4>
              <div dangerouslySetInnerHTML={{ __html: staticContents[0].personalization }} />

            </div>
          )}


          {
            showServicing && staticContents && staticContents.length > 0 && (
                <div className='product-care'>
                <h4 className='product-care-title'>Servicing</h4>

                <ServiceForm servicing={staticContents[0].servicing} />

              </div>
            )
          }



        </div>
      </div>
      <Footer />
    </>
  );
};

export default ServicePage;
