import React from 'react';
import './About.css'; 

const About = ({displayPictures, staticContents}) => {

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return (
    <>
    
      <div className="about-section">
        <div className="about-content">
          <div className="about-image">
          {
            displayPictures && displayPictures.length > 0 && 
            <img src={`${baseUrl}/files/${displayPictures[0].collectionId}/${displayPictures[0].id}/${displayPictures[0].aboutLelord}`} alt="About" />
          }
            </div>
            <div className='about-text'>
              { staticContents && staticContents.length > 0 && 
                        <div dangerouslySetInnerHTML={{ __html: staticContents[0].lelordTagline }} />

              }
          </div>
        
        </div>
      </div>
    
    </>
  );
};

export default About;
