import React, { useState } from 'react';
import PocketBase from 'pocketbase';
import '../Repair/Repair.css';
import CustomSelectField from '../CustomSelectField/CustomSelectField';

const pb = new PocketBase(process.env.REACT_APP_POCKETBASE_API_URL);

const ServiceForm = ({servicing}) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const [successMsg, setSuccessMsg] = useState(false);
  const [error, setError] = useState('');
  const [productType, setProductType] = useState('')

  const [formData, setFormData] = useState({
    customer_name: '',
    contact_number: '',
    servicing_date: '',
  });

  const handleChange = (option) => {
    setProductType(option.value)
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

      setFormData({
        ...formData,
        [name]: value,
      });

      // Clear error for contact_number when it becomes valid
      if (name === 'contact_number') {
        const contactNumberRegex = /^\d{9}$/;
        if (contactNumberRegex.test(value)) {
          setError('');
        }
      }
    
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Check if contact number is exactly 9 digits
    const contactNumberRegex = /^\d{8,12}$/;
    if (!contactNumberRegex.test(formData.contact_number)) {
      setError('Contact number must be between 8 to 12 digits.');
      return;
    }

    // Validation: Ensure all fields are filled
    if (!formData.customer_name || !formData.contact_number || !formData.servicing_date) {
      setError('Please fill in all fields before submitting.');
      return;
    }

    try {
      // Submit form data to the server
      await pb.collection('servicings').create(formData);
      setSuccessMsg(true);
      setError('');

      // Reset form fields after successful submission
      setFormData({
        customer_name: '',
        email: '',
        contact_number: '',
        servicing_date: ''
      });

      setProductType('')


      // Hide success message after 3 seconds
      setTimeout(() => {
        setSuccessMsg(false);
      }, 3000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('Failed to submit the form. Please try again.');
    }
  };

  const options = [
    { label: 'Shoes', value: 'shoes' },
    { label: 'Boot', value: 'boot' },
    { label: 'Jacket', value: 'jacket' },
    { label: 'Shirt', value: 'shirt' },
    { label: 'Pant', value: 'pant' },
  ];

  return (
    <>
      <div className="repair-container">
        <div className="repair-form-container">
          <h2>Please complete the servicing form to proceed with your request.</h2>
          <form onSubmit={handleSubmit}>
            <label>
              Customer Name
              <input
                type="text"
                name="customer_name"
                value={formData.customer_name}
                onChange={handleInputChange}
                required
              />
            </label>


            <CustomSelectField
              options={options}
              placeholder="Product Type"
              onChange={handleChange}
              value={productType}
            />



            <label>
              Contact Number
              <input
                type="text"
                name="contact_number"
                value={formData.contact_number}
                onChange={handleInputChange}
                required
              />
            </label>

            <label>
              Request a booking Date
              
              <input
                type="date"
                name="servicing_date"
                value={formData.servicing_date}
                onChange={handleInputChange}
                required
                style={{
                  display:'block',
                  appearance: 'none',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '10px',
                  width: '100%',
                }}
              />
            </label>
           
            
            
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <button className="form-submit-btn" type="submit">Submit</button>
          </form>
          {successMsg && <div className="success-toast">Servicing requested! We will get back to you soon.</div>}
        </div>
        <div className="description-container">
          <h2>Service Description</h2>
          <p>
          
            <div dangerouslySetInnerHTML={{ __html: servicing }} />

          </p>
        </div>
      </div>
    </>
  );
};

export default ServiceForm;
